import { render, staticRenderFns } from "./MhMailjet2.vue?vue&type=template&id=28d74044"
import script from "./MhMailjet2.vue?vue&type=script&lang=js"
export * from "./MhMailjet2.vue?vue&type=script&lang=js"
import style0 from "./MhMailjet2.vue?vue&type=style&index=0&id=28d74044&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../../../../GbR/10__Projekte/2020-04-27__opelvillen/05  Website/opelvillen.de/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports